import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/BlogPost.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A few weeks ago, amid the ongoing COVID-19 pandemic, my apartment building left a note in my mailbox. Among the helpful information and polite requests to wear masks in shared areas, I noticed a piece of text that had a very familiar style. It was blue, and underlined, and seemed to hint at a missing piece of content. In short, it was a link. Of course, this being a printed piece of mail, the link wasn’t much help to me. And, while this note was certainly a Google Docs or Word document, it made me think about the way links are styled on the web.`}</p>
    <p>{`While people aren’t printing out websites at the same volume they used to, print styles are still very important. Modern browsers use these styles when saving a page as a PDF, for example. The problem is that links in this context are not always clickable. But with a little CSS we can ensure the user is always shown the URL the link points to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@media print {
  a:link[href^='http']::after {
    content: ' (' attr(href) ')';
  }

  a:link[href^='/']::after {
    content: ' (https://mywebsite.com' attr(href) ')';
  }

  a:link[href^='#'] {
    text-decoration: none;
  }
}
`}</code></pre>
    <p>{`This CSS takes advantage of the `}<inlineCode parentName="p">{`print`}</inlineCode>{` media query to set special styles on links in that context. Specifically, we are creating `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Pseudo-elements"
      }}>{`a psuedo-element`}</a>{` on links that begin with `}<inlineCode parentName="p">{`http`}</inlineCode>{` (absolute links) and `}<inlineCode parentName="p">{`/`}</inlineCode>{` (root-relative links). The `}<inlineCode parentName="p">{`content`}</inlineCode>{` of those elements is set to the link element’s `}<inlineCode parentName="p">{`href`}</inlineCode>{` attribute using `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/attr"
      }}>{`the `}<inlineCode parentName="a">{`attr`}</inlineCode>{` function`}</a>{`. As a bonus, we also remove the link-styling for anchor links, since those have no practical purpose in print contexts.`}</p>
    <p>{`Of course this isn’t an advised solution to `}<em parentName="p">{`every`}</em>{` link, but it is particularly useful for blog posts, technical documentation, and any content that a user might want to save to refer to later. Printing websites not be a common occurrence anymore, but setting print styles absolutely should be.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      